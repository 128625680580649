import { useState } from "react";
import eyeVectorImage from "../../../assets/images/Vector.png";
import moment from "moment";
import {
  getFlightBaggage,
  getFlightFare,
  getFlightInfo,
} from "../../../api/FlightAPI";
import LazyImage from "../../../pages/LazyImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { formatAmountAndCurrency } from "../../../utils/helpers";

interface CheapestFlightsListProps {
  cheapestFlightList: any;
  slabsListData: any;
  tag?: string;
}

export const CheapestFlightsList = ({
  cheapestFlightList,
  slabsListData,
  tag,
}: CheapestFlightsListProps) => {
  const [openFlightIndex, setOpenFlightIndex] = useState(null);
  const [storeSegments, setStoreSegments] = useState<any>([]);
  const [storeFareDetails, setStoreFareDetails] = useState<any>([]);
  const [baggageDetails, setBaggageDetails] = useState<any>([]);
  const [storeNotices, setStoreNotices] = useState<any>();
  const [markedPrice, setMarkedPrice] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [storedAddonPercent, setStoredAddonPercent] = useState(0);
  const [baseFareDetails, setBaseFareDetails] = useState<any>({
    baseFare: "",
    tax: "",
    ptcFare: "",
    discount: "",
    netAmount: "",
    commission: "",
  });

  const [toggleFare, setToggleFare] = useState("");

  const [toggleTabs, setToggleTabs] = useState("overview");

  const flightJourneyJSON = localStorage.getItem("flightJourney");
  const flightJourney = JSON.parse(String(flightJourneyJSON));

  const flightSearchDetailsJSON = localStorage.getItem("userFlightSearchData");
  const flightSearchDetails = JSON.parse(String(flightSearchDetailsJSON));

  const generateConnectionMessage = (
    flightConnection: any[] | null | undefined
  ) => {
    if (cheapestFlightList.length !== 0) {
      let connectionsMessage = "";
      if (flightConnection && flightConnection.length === 1) {
        connectionsMessage = `1 stop via ${
          flightConnection[0]?.ArrAirportName?.split("|")[1]
        }`;
      } else if (flightConnection && flightConnection.length > 1) {
        const connectionNames = flightConnection?.map(
          (connection) => connection?.ArrAirportName?.split("|")[1]
        );
        connectionsMessage = `${
          flightConnection.length
        } stops via ${connectionNames?.join(", ")}`;
      }

      return connectionsMessage;
    }
  };

  const triggerGetFLightInfo = async (
    amount: number,
    orderID: number,
    index: string
  ) => {
    await getFlightInfo({
      Trips: [
        {
          TUI: flightJourney?.tUI,
          Amount: amount,
          ChannelCode: null,
          OrderID: orderID,
          Index: index,
        },
      ],
      TripType: flightSearchDetails.flightWay === "one way" ? "ON" : "RT",
      ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
    })
      .then((response) => {
        if (response?.data.Trips !== null) {
          setStoreNotices(response?.data.Trips[0]?.Journey[0]?.Notices);
          setStoreSegments([response?.data.Trips[0]?.Journey[0]?.Segments]);
          setBaseFareDetails({
            ...baseFareDetails,
            baseFare:
              response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                .TotalBaseFare,
            tax: response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
              .TotalTax,
            ptcFare:
              response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares.PTCFare,
            discount:
              response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares
                .TotalCommission,
            netAmount:
              response?.data.Trips[0]?.Journey[0]?.Segments[0].Fares.NetFare,
          });
        } else {
          setStoreSegments([]);
          setBaseFareDetails({
            ...baseFareDetails,
            baseFare: 0,
            tax: 0,
            ptcFare: null,
            discount: 0,
            netAmount: 0,
          });
        }
      })
      .catch((error) => console.log(error));

    //fare
    await getFlightFare({
      Trips: [
        {
          Amount: amount,
          Index: index,
          OrderID: orderID,
          TUI: flightJourney?.tUI,
        },
      ],
      ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
      Mode: "AS",
      Options: "",
      Source: "CF",
      TripType: flightSearchDetails.flightWay === "one way" ? "ON" : "RT",
    })
      .then((response) => {
        if (response?.data.Trips !== null) {
          setStoreFareDetails([response?.data.Trips[0]?.Journey[0]]);
        } else {
          setStoreFareDetails([]);
        }
      })
      .catch((error) => console.log(error));

    // Baggage
    await getFlightBaggage({
      PaidSSR: false,
      ClientID: "FVI6V120g22Ei5ztGK0FIQ==",
      Source: "LV",
      Trips: [
        {
          Amount: amount,
          Index: index,
          OrderID: orderID,
          TUI: flightJourney?.tUI,
        },
      ],
    })
      .then((response) => {
        setBaggageDetails(response.data?.Trips[0]);
      })
      .catch((error) => console.log(error));
  };

  function calculateTimeDifference(startTime: any, endTime: any) {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    const timeDifferenceInMilliseconds = Math.abs(
      endDate.getTime() - startDate.getTime()
    );
    const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${String(hours).padStart(2, "0")}h ${String(minutes).padStart(
      2,
      "0"
    )}min`;
  }

  const sortFlightsByFare = (flights: any[]) => {
    return flights
      .slice()
      .sort((a: { NetFare: number }, b: { NetFare: number }) => {
        return a.NetFare - b.NetFare;
      });
  };

  const matchingFlights = cheapestFlightList?.filter(
    (flight: { Duration: string }) => {
      return flight?.Duration;
    }
  );

  const matchingDepartureFlight = cheapestFlightList
    .slice()
    .sort(
      (
        a: { DepartureTime: string | number | Date },
        b: { DepartureTime: string | number | Date }
      ) => {
        const departureTimeA: any = new Date(a.DepartureTime);
        const departureTimeB: any = new Date(b.DepartureTime);
        return departureTimeA - departureTimeB;
      }
    );

  const matchingArrivalFlight = cheapestFlightList
    .slice()
    .sort(
      (
        a: { ArrivalTime: string | number | Date },
        b: { ArrivalTime: string | number | Date }
      ) => {
        const arrivalTimeA: any = new Date(a.ArrivalTime);
        const arrivalTimeB: any = new Date(b.ArrivalTime);
        return arrivalTimeA - arrivalTimeB;
      }
    );

  const sortedFlights =
    tag === "departure"
      ? matchingDepartureFlight
      : tag === "arrival"
      ? matchingArrivalFlight
      : sortFlightsByFare(matchingFlights);

  const toggleFlightDetails = (flightIndex: any) => {
    if (openFlightIndex === flightIndex) {
      setOpenFlightIndex(null);
    } else {
      setOpenFlightIndex(flightIndex);
    }
  };

  const ptcFares = baseFareDetails.ptcFare || [];

  const adultCount = flightSearchDetails.ADT || 0;
  const childCount = flightSearchDetails.CHD || 0;
  const infantCount = flightSearchDetails.INF || 0;

  // Initialize tax totals for each passenger type
  const adultTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  const childTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };
  const infantTaxTotals = {
    AirlineMisc: 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  // Calculate taxes for each passenger type
  ptcFares?.forEach(
    (ptc: {
      PTC: any;
      OT: string;
      ST: string;
      YQ: string;
      YR: string;
      UD: string;
      API: string;
      PSF: string;
      K3: string;
      VATonServiceCharge: string;
    }) => {
      const ptcType = ptc.PTC;
      // Calculate taxes based on passenger type
      switch (ptcType) {
        case "ADT":
          const otValues =
            typeof ptc?.OT === "string"
              ? ptc?.OT?.split(",")?.map(
                  (value: string) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSum = otValues?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );

          adultTaxTotals.AirlineMisc +=
            (otSum + parseFloat(ptc.ST)) * adultCount;
          adultTaxTotals.FuelTax += parseFloat(ptc.YQ) * adultCount;
          adultTaxTotals.CongestionFee += parseFloat(ptc.YR) * adultCount;
          adultTaxTotals.UserDevelopmentFee += parseFloat(ptc.UD) * adultCount;
          adultTaxTotals.APITax += parseFloat(ptc.API) * adultCount;
          adultTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * adultCount;
          adultTaxTotals.GST += parseFloat(ptc.K3) * adultCount;
          adultTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * adultCount;
          break;
        case "CHD":
          const otValuesChild =
            typeof ptc?.OT === "string"
              ? ptc?.OT?.split(",")?.map(
                  (value: string) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSumChild = otValuesChild?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );
          childTaxTotals.AirlineMisc +=
            (otSumChild + parseFloat(ptc.ST)) * childCount;

          childTaxTotals.FuelTax += parseFloat(ptc.YQ) * childCount;
          childTaxTotals.CongestionFee += parseFloat(ptc.YR) * childCount;
          childTaxTotals.UserDevelopmentFee += parseFloat(ptc.UD) * childCount;
          childTaxTotals.APITax += parseFloat(ptc.API) * childCount;
          childTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * childCount;
          childTaxTotals.GST += parseFloat(ptc.K3) * childCount;
          childTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * childCount;
          break;
        case "INF":
          const otValuesInfant =
            typeof ptc?.OT === "string"
              ? ptc?.OT?.split(",")?.map(
                  (value: string) => parseFloat(value.trim()) || 0
                )
              : [];

          const otSumInfant = otValuesInfant?.reduce(
            (acc: number, currentValue: number) => acc + currentValue,
            0
          );
          infantTaxTotals.AirlineMisc +=
            (otSumInfant + parseFloat(ptc.ST)) * infantCount;
          infantTaxTotals.FuelTax += parseFloat(ptc.YQ) * infantCount;
          infantTaxTotals.CongestionFee += parseFloat(ptc.YR) * infantCount;
          infantTaxTotals.UserDevelopmentFee +=
            parseFloat(ptc.UD) * infantCount;
          infantTaxTotals.APITax += parseFloat(ptc.API) * infantCount;
          infantTaxTotals.PassengerServiceTax +=
            parseFloat(ptc.PSF) * infantCount;
          infantTaxTotals.GST += parseFloat(ptc.K3) * infantCount;
          infantTaxTotals.VATonServiceCharge +=
            parseFloat(ptc.VATonServiceCharge) * infantCount;
          break;
        default:
      }
    }
  );

  const taxTotals: any = {
    AirlineMisc: 0 + storedAddonPercent ? Math.round(storedAddonPercent) : 0,
    FuelTax: 0,
    CongestionFee: 0,
    UserDevelopmentFee: 0,
    APITax: 0,
    PassengerServiceTax: 0,
    GST: 0,
    VATonServiceCharge: 0,
  };

  function calculateFare(tax: any, passengerCount: number) {
    const otValues =
      typeof tax?.OT === "string"
        ? tax?.OT.split(",")?.map(
            (value: string) => parseFloat(value.trim()) || 0
          )
        : [];

    const otSum = otValues?.reduce(
      (acc: number, currentValue: number) => acc + currentValue,
      0
    );

    return {
      AirlineMisc: Math.round(otSum + tax.ST) * passengerCount,
      FuelTax: tax.YQ * passengerCount || 0,
      CongestionFee: parseFloat(tax.YR) * passengerCount || 0,
      UserDevelopmentFee: parseFloat(tax.UD) * passengerCount || 0,
      APITax: parseFloat(tax.API) * passengerCount || 0,
      PassengerServiceTax: parseFloat(tax.PSF) * passengerCount || 0,
      GST: parseFloat(tax.K3) * passengerCount || 0,
      VATonServiceCharge:
        parseFloat(tax.VATonServiceCharge) * passengerCount || 0,
    };
  }

  ptcFares?.forEach((ptc: any) => {
    const ptcType = ptc.PTC;
    const passengerCount =
      ptcType === "ADT"
        ? adultCount
        : ptcType === "CHD"
        ? childCount
        : infantCount;

    const fareDetails: any = calculateFare(ptc, passengerCount);

    for (const key in taxTotals) {
      taxTotals[key] += fareDetails[key];
    }
  });

  const totalTaxAmount = Object.values(taxTotals)?.reduce(
    (acc: any, value: any) => acc + value,
    0
  );

  const discountCalc = markedPrice - sellingPrice;

  const discountRate = markedPrice
    ? Math.round(discountCalc + baseFareDetails?.discount * 0.95)
    : Math.round(baseFareDetails?.discount * 0.95);

  const totalFareAmmount = Math.round(
    baseFareDetails.baseFare + totalTaxAmount - discountRate
  );

  const flightSegments = storeSegments
    .flat()
    .map((d: { Flight: any }) => d.Flight);

  const combinedBaggage =
    baggageDetails?.Journey &&
    flightSegments.map((flight: any, index: number) => {
      if (index < baggageDetails?.Journey[0]?.Segments?.length) {
        return {
          ...flight,
          SSR: baggageDetails?.Journey[0]?.Segments[index]?.SSR,
        };
      }
      return flight;
    });

  // Slab calculation for discount - start
  const findSlab = (netFare: number) => {
    return slabsListData.flightSlabs.find(
      (slab: { minAmount: number; maxAmount: number }) =>
        netFare >= slab.minAmount && netFare <= slab.maxAmount
    );
  };

  const updateFlight = (flight: {
    NetFare: any;
    CommissionRate: any;
    AddonRate: any;
    SellingPrice: any;
    MarkedPrice: any;
    Discount: any;
    DiscountAmount: any;
  }) => {
    const netFare = flight.NetFare;
    const slab = findSlab(netFare);

    const markedPriceRate =
      flight.NetFare + flight.NetFare * (slab?.addonRate / 100);
    const sellingPriceRate =
      flight.NetFare + flight.NetFare * (slab?.commissionRate / 100);

    if (slab) {
      flight.CommissionRate = slab?.commissionRate;
      flight.AddonRate = slab?.addonRate;
      flight.MarkedPrice = Math.round(markedPriceRate);
      flight.SellingPrice = Math.round(sellingPriceRate + 1);

      flight.Discount = Math.round(
        ((flight.MarkedPrice - flight.SellingPrice) / flight.MarkedPrice) * 100
      );
      flight.DiscountAmount = Math.round(
        flight.MarkedPrice - flight.SellingPrice
      );
    }

    return flight;
  };

  const updatedFlights = sortedFlights
    .map(
      (flight: {
        NetFare: any;
        CommissionRate: any;
        AddonRate: any;
        SellingPrice: any;
        MarkedPrice: any;
        Discount: any;
        DiscountAmount: any;
      }) => {
        if (flight.NetFare !== 0) {
          return updateFlight(flight);
        } else {
          return null;
        }
      }
    )
    .filter((flight: any) => flight !== null);

  const newCalculateTotalDuration = (segments: any[], currentIndex: number) => {
    let totalDuration = 0;
    let layoverDurations: string[] = [];

    if (Array.isArray(segments)) {
      segments.slice(0, currentIndex + 1).forEach(({ Flight }, index) => {
        if (Flight && index > 0) {
          const layover = moment(Flight.DepartureTime).diff(
            moment(segments[index - 1].Flight.ArrivalTime)
          );
          totalDuration += layover;

          const individualDuration = TimecalculateTimeDifference(
            segments[index - 1].Flight.ArrivalTime,
            Flight.DepartureTime
          );
          if (index === currentIndex) {
            // Only push the duration for the current segment
            layoverDurations.push(individualDuration);
          }
        }
      });
    }

    let resultString = layoverDurations.join(" ");
    return resultString;
  };

  const TimecalculateTimeDifference = (
    startTime: string,
    endTime: string
  ): string => {
    const startMoment = moment(startTime);
    const endMoment = moment(endTime);
    const duration = moment.duration(endMoment.diff(startMoment));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;

    return `${hours}h ${minutes}m`;
  };

  return (
    <>
      {updatedFlights?.map((flight: any, index: number) => (
        <section
          className="cheapest_section"
          id="cheapest_section"
          key={flight.Index + index + "cheapest flight"}
        >
          <div className="card card_section">
            <div className="card-body">
              <div className="main_content res_containt">
                <div className="block">
                  <div className="card-img">
                    {" "}
                    <LazyImage
                      className="img-fluid"
                      src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${flight.MAC}.jpg`}
                      alt="Emirates"
                    />{" "}
                  </div>
                  <div className="card-content">
                    <h3 className="h3-bold mb-0 res-font">
                      {flight?.AirlineName?.split("|")[1]}
                    </h3>
                    <p className="card-text">
                      {flight.MAC} {"-"} {flight.FlightNo}
                    </p>
                  </div>
                </div>
                <div className="block">
                  <div className="card-content">
                    <p className="card-text mb-2">Departure</p>
                    <h3 className="h3-bold res-font">
                      {moment(flight.DepartureTime).format("HH:mm")}
                    </h3>
                    <span>
                      {" "}
                      {moment(flight.DepartureTime).format("DD MMM YYYY")}
                    </span>
                    <p
                      title={flight?.FromName?.split("|")[1]}
                      className="card-text text_overflow"
                    >
                      {flight?.FromName?.split("|")[1]}
                    </p>
                  </div>
                </div>
                <div className="card-content content_body">
                  <p className="card-text mb-2" id="duration">
                    {flight.Duration}
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="left-image">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        viewBox="0 0 256 256"
                        data-testid="originIcon"
                      >
                        <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                      </svg>
                    </div>
                    <div className="dot-arrow">
                      {flight.Connections &&
                        flight.Connections.length !== 0 && (
                          <span className="dot-span"> </span>
                        )}
                    </div>
                    <div className="left-image">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="currentColor"
                        viewBox="0 0 256 256"
                        data-testid="destinationIcon"
                      >
                        <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.9A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                      </svg>
                    </div>
                  </div>

                  {flight.Connections && flight.Connections.length === 0 ? (
                    <>
                      <p className="card-text"> Non stop </p>
                      <p
                        className="card-text"
                        style={{ visibility: "hidden", height: "0px" }}
                      >
                        some some some te{" "}
                      </p>
                    </>
                  ) : (
                    <p className="card-text">
                      {" "}
                      {generateConnectionMessage(flight.Connections)}
                    </p>
                  )}
                </div>

                <div className="block">
                  <div className="card-content">
                    <p className="card-text mb-2">Arrival</p>
                    <h3 className="h3-bold res-font">
                      {" "}
                      {moment(flight.ArrivalTime).format("HH:mm")}
                    </h3>
                    <span>
                      {moment(flight.ArrivalTime).format("DD MMM YYYY")}
                    </span>
                    <p
                      title={flight?.ToName?.split("|")[1]}
                      className="card-text text_overflow"
                    >
                      {flight?.ToName?.split("|")[1]}
                    </p>
                  </div>
                </div>
                <div className="block">
                  <div className="card-content">
                    <div
                      style={{
                        marginBottom: "0.5rem",
                        color: "darkred",
                        fontSize: "medium",
                        fontWeight: "600",
                      }}
                    >
                      {flight?.FCType === "SME FARE"
                        ? "Corporate Fare"
                        : flight?.FCType === "STUDENT FARE"
                        ? "Student Fare"
                        : flight?.FCType === "SENIOR CITIZEN"
                        ? "Sr. Citizen Fare"
                        : null}
                    </div>

                    {flight?.MarkedPrice && (
                      <span
                        style={{
                          textDecoration: "line-through",
                          color: "orangered",
                          fontSize: "13px",
                          marginLeft: "14px",
                        }}
                      >
                        ₹{flight.MarkedPrice}
                      </span>
                    )}

                    {flight?.SellingPrice ? (
                      <h3 className="h3-bold res-font">
                        ₹{flight?.SellingPrice?.toLocaleString("en-IN")}
                      </h3>
                    ) : (
                      <h3 className="h3-bold res-font">
                        ₹{flight?.NetFare?.toLocaleString("en-IN")}
                      </h3>
                    )}

                    {flight.Discount > 0 && (
                      <span style={{ fontSize: "12px", color: "green" }}>
                        ₹{flight.MarkedPrice - flight?.SellingPrice} Savings
                      </span>
                    )}
                  </div>
                </div>
                <div className="block">
                  <div className="book-now-btn">
                    <button
                      className="btn btn-login"
                      type="button"
                      style={{ width: "100%" }}
                      onClick={() => {
                        const flightBookingData = {
                          fare: flight?.SellingPrice
                            ? flight?.SellingPrice
                            : flight.NetFare,
                          orderId: 1,
                          index: flight.Index,
                        };
                        localStorage.setItem(
                          "bookingDetails",
                          JSON.stringify(flightBookingData)
                        );
                        window.open("/flight-checkout", "_blank");
                      }}
                    >
                      Book now
                    </button>
                  </div>
                </div>
              </div>
              <span
                style={{
                  fontSize: "small",
                  paddingLeft: "4px",
                  color: "#a45050",
                }}
              >
                {flight.MAC === flight.OAC
                  ? ""
                  : `Operated By : ${flight?.AirlineName?.split("|")[2]}`}
              </span>
              <div className="card-footer-section">
                {flight.Refundable === "Y" ? (
                  <span className="card-left"> Partially Refundable</span>
                ) : flight.Refundable === "R" ? (
                  <span className="card-left">Refundable</span>
                ) : (
                  <span className="card-left" style={{ color: "red" }}>
                    Non Refundable
                  </span>
                )}
                <span
                  onClick={() => {
                    toggleFlightDetails(flight.Index);
                    triggerGetFLightInfo(
                      flight?.SellingPrice
                        ? flight?.SellingPrice
                        : flight.NetFare,
                      1,
                      flight.Index
                    );
                    setToggleTabs("overview");
                    setToggleFare("");
                    if (flight?.SellingPrice || flight?.MarkedPrice) {
                      setMarkedPrice(flight?.MarkedPrice);
                      setSellingPrice(flight?.SellingPrice);
                      setStoredAddonPercent(
                        flight.NetFare * (flight?.AddonRate / 100)
                      );
                    } else {
                      setMarkedPrice(0);
                      setSellingPrice(0);
                      setStoredAddonPercent(0);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                  className="card-right first_card collapsed"
                >
                  <span>
                    <LazyImage
                      className="img-fluid"
                      src={eyeVectorImage}
                      alt="view-icon"
                    />
                  </span>
                  View Flight Details
                </span>
              </div>

              <div
                className="collapse"
                key={flight.Index + Math.random() + 1}
                id={`collapseExample-${flight.Index}`}
                style={{
                  display: openFlightIndex === flight.Index ? "block" : "none",
                }}
              >
                <hr />
                <div className="d-flex justify-content-start gap-3 select-tab">
                  <div
                    className={
                      toggleTabs === "overview"
                        ? "card_overview active"
                        : "card_overview"
                    }
                    onClick={() => setToggleTabs("overview")}
                  >
                    <div className="card-body px-2 py-1">
                      <h5 className="card-title tab_heading mb-0">Overview</h5>
                    </div>
                  </div>

                  <div
                    className={
                      toggleTabs === "Fare summary"
                        ? "card_summary active"
                        : "card_summary"
                    }
                    onClick={() => setToggleTabs("Fare summary")}
                  >
                    <div className="card-body px-2 py-1">
                      <h5 className="card-title tab_heading mb-0">
                        Fare summary rules
                      </h5>
                    </div>
                  </div>
                  <div
                    className={
                      toggleTabs === "Free Baggage"
                        ? "card_baggge active"
                        : "card_baggge"
                    }
                    onClick={() => setToggleTabs("Free Baggage")}
                  >
                    <div className="card-body px-2 py-1">
                      <h5 className="card-title tab_heading mb-0">
                        Free Baggage
                      </h5>
                    </div>
                  </div>
                </div>
                {/* // Overview of connecting airports  */}
                {toggleTabs === "overview" && (
                  <div className="mt-3 overview">
                    {storeSegments[0]?.map(
                      (
                        { Flight }: any,
                        index: number,
                        flightDetails: any,
                        indexFlight: number
                      ) => (
                        <>
                          {indexFlight !== storeSegments[0].length - 1 && (
                            <>
                              {index !== 0 && (
                                <div className="change-plan">
                                  <h3 className="h3-bold" key={index}>
                                    Change of planes・
                                    <span>
                                      {/* {calculateTimeDifference(
                                        Flight.DepartureTime,
                                        Flight.ArrivalTime
                                      )} */}
                                      {newCalculateTotalDuration(
                                        storeSegments[0],
                                        index
                                      )}{" "}
                                      Layover in{" "}
                                      {Flight?.DepAirportName?.split("|")[0]}{" "}
                                    </span>
                                  </h3>
                                </div>
                              )}

                              <div className="d-flex justify-content-between align-items-center pb-3">
                                <div className="block-view-details gap-2 py-0">
                                  <LazyImage
                                    className="img-fluid"
                                    src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${Flight.MAC}.jpg`}
                                    alt="Emirates"
                                  />
                                  <h3 className="h3-bold res-font mb-0">
                                    {Flight?.Airline?.split("|")[1]}
                                  </h3>
                                  <p className="card-text res-font">
                                    {Flight.MAC} {"-"} {Flight.FlightNo}
                                  </p>
                                </div>
                                <div className="d-flex flex-column">
                                  <div className="city-name d-flex justify-content-end gap-2">
                                    <p className="card-text mb-0">Aircraft</p>
                                    <p className="card-text mb-0">
                                      {
                                        storeSegments[0]?.map(
                                          ({ Flight }: any, index: number) =>
                                            Flight.AirCraft
                                        )[0]
                                      }
                                    </p>
                                  </div>
                                  <div className="city-name d-flex justify-content-end gap-2">
                                    <p className="card-text mb-0">
                                      Travel Class
                                    </p>
                                    <p
                                      className="card-text mb-0"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {flightSearchDetails?.Cabin}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div
                            className="main_content hops_position"
                            key={`segments-${index}`}
                          >
                            <div className="block justify-content-end">
                              <div className="card-content">
                                <h3 className="h3-bold text-end res-font">
                                  {moment(Flight.DepartureTime).format("HH:mm")}
                                </h3>
                                <p className="card-text mb-1 text-end">
                                  {moment(Flight.DepartureTime).format(
                                    "DD MMM YYYY"
                                  )}
                                </p>
                                <p className="card-text mb-0 text-end">
                                  {Flight.DepartureTerminal !== "" &&
                                    `Terminal ${Flight.DepartureTerminal}`}
                                </p>
                                <p className="card-text text-end">
                                  {Flight.DepAirportName?.split("|")[0]}
                                  <div>
                                    {Flight.DepAirportName?.split("|")[1]}
                                  </div>
                                </p>
                              </div>
                            </div>

                            <div className="block justify-content-center">
                              <div className="card-content content_body duration">
                                <p className="card-text mb-2 time-duration">
                                  {/* {Flight.Duration} */}
                                  {Flight?.Hops ? (
                                    <>
                                      {storeSegments[0]?.map(
                                        (segment: any, segmentIndex: any) => (
                                          <div key={segmentIndex}>
                                            {segment?.Flight?.Hops?.map(
                                              (hop: any, hopIndex: any) => (
                                                <div key={hopIndex}>
                                                  {hop?.ArrivalDuration}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {calculateTimeDifference(
                                        Flight.DepartureTime,
                                        Flight.ArrivalTime
                                      )}
                                    </>
                                  )}
                                </p>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="left-image">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="currentColor"
                                      viewBox="0 0 256 256"
                                      data-testid="originIcon"
                                    >
                                      <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                                    </svg>
                                  </div>
                                  <div className="dot-arrow"></div>
                                  <div className="left-image">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      fill="currentColor"
                                      viewBox="0 0 256 256"
                                      data-testid="destinationIcon"
                                    >
                                      <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.9A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                                    </svg>
                                  </div>
                                </div>
                                <p
                                  className="card-text"
                                  style={{
                                    visibility: "hidden",
                                    height: "0px",
                                  }}
                                >
                                  some some some te
                                </p>
                              </div>
                            </div>

                            {Flight?.Hops !== null ? (
                              <>
                                {Flight?.Hops.map(
                                  (hops: any, hopIndex: number) => (
                                    <>
                                      <div className="block">
                                        <div className="card-content">
                                          <h3 className="h3-bold res-font">
                                            {moment(hops?.ArrivalTime).format(
                                              // "LT"
                                              "HH:mm"
                                            )}
                                          </h3>
                                          <p className="card-text mb-2">
                                            {" "}
                                            {moment(hops?.ArrivalTime).format(
                                              "DD MMM YYYY"
                                            )}
                                          </p>
                                          <p className="card-text mb-0">
                                            {" "}
                                            {hops?.ArrivalTerminal &&
                                              `Terminal ${hops?.ArrivalTerminal}`}
                                          </p>
                                          <p className="card-text">
                                            {
                                              hops?.ArrAirportName?.split(
                                                "|"
                                              )[0]
                                            }
                                            <div>
                                              {
                                                hops?.ArrAirportName?.split(
                                                  "|"
                                                )[1]
                                              }
                                            </div>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="change-plan hops-changes-plan">
                                        {Flight?.Hops?.map(
                                          (hop: any, hopIndex: number) => (
                                            <h5
                                              className="hops text plan"
                                              key={hopIndex}
                                            >
                                              Connecting Time :
                                              <span>
                                                {calculateTimeDifference(
                                                  hop.DepartureTime,
                                                  hop.ArrivalTime
                                                )}{" "}
                                              </span>
                                            </h5>
                                          )
                                        )}
                                      </div>

                                      <div className="block justify-content-end">
                                        <div className="card-content">
                                          <h3 className="h3-bold text-end res-font">
                                            {moment(hops?.DepartureTime).format(
                                              // "LT"
                                              "HH:mm"
                                            )}
                                          </h3>
                                          <p className="card-text mb-1 text-end">
                                            {moment(hops?.DepartureTime).format(
                                              "DD MMM YYYY"
                                            )}
                                          </p>
                                          <p className="card-text mb-0 text-end">
                                            {hops?.ArrivalTerminal &&
                                              `Terminal ${hops?.ArrivalTerminal}`}
                                          </p>
                                          <p className="card-text text-end">
                                            {
                                              hops?.ArrAirportName?.split(
                                                "|"
                                              )[0]
                                            }
                                            <div>
                                              {
                                                hops?.ArrAirportName?.split(
                                                  "|"
                                                )[1]
                                              }
                                            </div>
                                          </p>
                                        </div>
                                      </div>

                                      <div className="block justify-content-center">
                                        <div className="card-content content_body">
                                          <p className="card-text mb-2">
                                            {/* {Flight.Duration} */}
                                            {storeSegments[0]?.map(
                                              (
                                                segment: any,
                                                segmentIndex: any
                                              ) => (
                                                <div key={segmentIndex}>
                                                  {segment?.Flight?.Hops?.map(
                                                    (
                                                      hop: any,
                                                      hopIndex: any
                                                    ) => (
                                                      <div key={hopIndex}>
                                                        {hop?.DepartureDuration}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </p>
                                          <div className="d-flex justify-content-between align-items-center">
                                            <div className="left-image">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                fill="currentColor"
                                                viewBox="0 0 256 256"
                                                data-testid="originIcon"
                                              >
                                                <path d="M174,216a6,6,0,0,1-6,6H24a6,6,0,0,1,0-12H168A6,6,0,0,1,174,216ZM245.9,92.78a6,6,0,0,1-2.82,4l-147.41,88a38.22,38.22,0,0,1-19.23,5.23,37.8,37.8,0,0,1-25.92-10.33l-.1-.09L14.37,144.36a14,14,0,0,1,4-23l3-1.49a6,6,0,0,1,4.56-.29l29.15,9.83,23.17-14-23.7-23a14,14,0,0,1,4-23.18l.24-.10,7.15-2.71a6,6,0,0,1,4.19,0l54.84,20.18,52.38-31.27A37.81,37.81,0,0,1,226,64l.09.11L244.73,88A6,6,0,0,1,245.9,92.78ZM231.09,90,216.67,71.53a25.86,25.86,0,0,0-33.26-5.89L128.6,98.36a6,6,0,0,1-5.15.48L68,78.45l-4.9,1.85A1.91,1.91,0,0,0,62,81.77a2,2,0,0,0,.63,1.82l.17.15,29.35,28.49a6,6,0,0,1-1.07,9.44L58.89,141.16a6,6,0,0,1-5,.55l-29.45-9.94-.93.46-.28.13a2,2,0,0,0-.58,3.29l.10.09,36,35.28a25.84,25.84,0,0,0,30.81,3.47Z"></path>
                                              </svg>
                                            </div>
                                            <div className="dot-arrow"></div>
                                            <div className="left-image">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="18"
                                                height="18"
                                                fill="currentColor"
                                                viewBox="0 0 256 256"
                                                data-testid="destinationIcon"
                                              >
                                                <path d="M246,216a6,6,0,0,1-6,6H96a6,6,0,0,1,0-12H240A6,6,0,0,1,246,216Zm-23.62-26.22L45.75,140.32A38.14,38.14,0,0,1,18,103.72V48A14,14,0,0,1,36.43,34.71l5.47,1.83a6,6,0,0,1,3.74,3.65l11,30.33L90,80V48a14,14,0,0,1,18.43-13.29l5.47,1.83a6,6,0,0,1,3.63,3.37l22.88,54.53,61.77,17.27A38.09,38.09,0,0,1,230,148.32V184a6,6,0,0,1-7.62,5.78ZM218,148.32a26.07,26.07,0,0,0-19-25l-64.58-18a6,6,0,0,1-3.91-3.46l-23-54.7-2.89-1A2,2,0,0,0,102,48V88a6,6,0,0,1-7.64,5.77l-44-12.54a6,6,0,0,1-4-3.73L35.34,47l-2.71-.9A1.91,1.91,0,0,0,32,46a2,2,0,0,0-1.16.38A2,2,0,0,0,30,48v55.72a26.09,26.09,0,0,0,19,25l169,47.33Z"></path>
                                              </svg>
                                            </div>
                                          </div>
                                          <p
                                            className="card-text"
                                            style={{
                                              visibility: "hidden",
                                              height: "0px",
                                            }}
                                          >
                                            some some some te
                                          </p>
                                        </div>
                                      </div>
                                      <div className="block">
                                        <div className="card-content">
                                          <h3 className="h3-bold res-font">
                                            {moment(Flight.ArrivalTime).format(
                                              // "LT"
                                              "HH:mm"
                                            )}
                                          </h3>
                                          <p className="card-text mb-2">
                                            {" "}
                                            {moment(Flight.ArrivalTime).format(
                                              "DD MMM YYYY"
                                            )}
                                          </p>
                                          <p className="card-text mb-0">
                                            {" "}
                                            {Flight.ArrivalTerminal !== "" &&
                                              `Terminal ${Flight.ArrivalTerminal}`}
                                          </p>
                                          <p className="card-text">
                                            {
                                              Flight?.ArrAirportName?.split(
                                                "|"
                                              )[0]
                                            }
                                            <div>
                                              {
                                                Flight?.ArrAirportName?.split(
                                                  "|"
                                                )[1]
                                              }
                                            </div>
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  )
                                )}
                              </>
                            ) : (
                              <>
                                <div className="block">
                                  <div className="card-content">
                                    <h3 className="h3-bold res-font">
                                      {moment(Flight.ArrivalTime).format(
                                        "HH:mm"
                                      )}
                                    </h3>
                                    <p className="card-text mb-2">
                                      {" "}
                                      {moment(Flight.ArrivalTime).format(
                                        "DD MMM YYYY"
                                      )}
                                    </p>
                                    <p className="card-text mb-0">
                                      {" "}
                                      {Flight.ArrivalTerminal !== "" &&
                                        `Terminal ${Flight.ArrivalTerminal}`}
                                    </p>
                                    <p className="card-text">
                                      {Flight?.ArrAirportName?.split("|")[0]}
                                      <div>
                                        {Flight?.ArrAirportName?.split("|")[1]}
                                      </div>
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )
                    )}
                    {storeNotices &&
                      storeNotices.length > 0 &&
                      storeNotices.flat()?.map((notice: any, index: any) => (
                        <div
                          key={index}
                          style={{
                            border: "1px solid #ccc",
                            padding: "7px",
                            marginBottom: "2px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            style={{ color: "#ffb22e", marginRight: "6px" }}
                          />
                          <span>{notice?.Notice?.replace(/\n/g, " ")}</span>
                        </div>
                      ))}
                  </div>
                )}
                {toggleTabs === "Fare summary" && (
                  <div className="mt-3 summary">
                    <div className="row">
                      <div className="col-sm-4">
                        <h3 className="h3-bold">Fare Details</h3>
                        <div className="confirmation-box px-1">
                          <div className="confirm-box">
                            <div className="main-box">
                              {toggleFare === "base" ? (
                                <LazyImage
                                  className="image-arrow active"
                                  width={18}
                                  height={18}
                                  src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                  alt="collapse-arrow"
                                />
                              ) : (
                                <LazyImage
                                  className="image-arrow"
                                  id="image_first"
                                  width={18}
                                  height={18}
                                  src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                  alt="collapse-arrow"
                                />
                              )}

                              <div
                                className="box-header accordion"
                                id="acc_image_first"
                                onClick={() =>
                                  setToggleFare(
                                    toggleFare === "base" ? "" : "base"
                                  )
                                }
                              >
                                <h3 className="box-heading">Base Fare</h3>
                                <h3 className="box-heading">
                                  {" "}
                                  ₹
                                  {baseFareDetails.baseFare.toLocaleString(
                                    "en-IN"
                                  )}
                                </h3>
                              </div>
                              <ul
                                className="box-dropdown w3-animate-left"
                                id="box_first"
                                style={{
                                  display:
                                    toggleFare === "base" ? "block" : "none",
                                }}
                              >
                                <li className="box-content">
                                  {baseFareDetails?.ptcFare &&
                                    baseFareDetails?.ptcFare?.map(
                                      (fare: any, index: number) => (
                                        <>
                                          <div
                                            className="box-subcontent"
                                            key={`index-flight-${index}`}
                                          >
                                            <p className="box-list">
                                              {" "}
                                              {fare?.PTC === "ADT"
                                                ? "Adult"
                                                : fare?.PTC === "CHD"
                                                ? "Child"
                                                : "Infant"}{" "}
                                              (
                                              {fare.PTC === "ADT"
                                                ? flightSearchDetails?.ADT
                                                : fare?.PTC === "CHD"
                                                ? flightSearchDetails?.CHD
                                                : flightSearchDetails?.INF}{" "}
                                              X ₹ {fare?.Fare})
                                            </p>{" "}
                                            <p className="box-list">
                                              ₹{" "}
                                              {fare.PTC === "ADT"
                                                ? flightSearchDetails?.ADT *
                                                  fare?.Fare
                                                : fare?.PTC === "CHD"
                                                ? flightSearchDetails?.CHD *
                                                  fare?.Fare
                                                : flightSearchDetails?.INF *
                                                  fare?.Fare}
                                            </p>
                                          </div>
                                        </>
                                      )
                                    )}
                                </li>
                              </ul>
                            </div>
                            <hr className="mb-0" />
                            <div className="main-box">
                              {toggleFare === "tax" ? (
                                <LazyImage
                                  className="image-arrow active"
                                  width={18}
                                  height={18}
                                  src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                  alt="collapse-arrow"
                                />
                              ) : (
                                <LazyImage
                                  className="image-arrow"
                                  id="image_first"
                                  width={18}
                                  height={18}
                                  src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                  alt="collapse-arrow"
                                />
                              )}
                              <div
                                className="box-header accordion"
                                id="acc_image_third"
                              >
                                <h3
                                  className="box-heading"
                                  onClick={() =>
                                    setToggleFare(
                                      toggleFare === "tax" ? "" : "tax"
                                    )
                                  }
                                >
                                  Tax and charges
                                </h3>
                                <h3 className="box-heading">
                                  ₹{" "}
                                  {Number(totalTaxAmount)?.toLocaleString(
                                    "en-IN"
                                  )}
                                </h3>
                              </div>
                              <ul
                                className="box-dropdown w3-animate-left"
                                style={{
                                  display:
                                    toggleFare === "tax" ? "block" : "none",
                                }}
                              >
                                {Object.keys(taxTotals)?.map((taxType: any) => (
                                  <>
                                    {taxTotals[taxType] > 0 && (
                                      <li className="box-content" key={taxType}>
                                        <div className="box-subcontent">
                                          <p className="box-list">{taxType}</p>
                                          <p className="box-list">
                                            ₹ {taxTotals[taxType]}
                                          </p>
                                        </div>
                                      </li>
                                    )}
                                  </>
                                ))}
                              </ul>
                            </div>

                            {discountRate > 0 && (
                              <div className="main-box">
                                {toggleFare === "discount" ? (
                                  <img
                                    loading="lazy"
                                    className="image-arrow active"
                                    width="18"
                                    height="18"
                                    src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                    alt="collapse-arrow"
                                  />
                                ) : (
                                  <img
                                    loading="lazy"
                                    className="image-arrow"
                                    id="image_first"
                                    width="18"
                                    height="18"
                                    src="https://img.icons8.com/sf-regular/48/collapse-arrow.png"
                                    alt="collapse-arrow"
                                  />
                                )}
                                <div
                                  className="box-header accordion"
                                  id="acc_image_third"
                                >
                                  <h3
                                    className="box-heading"
                                    onClick={() =>
                                      setToggleFare(
                                        toggleFare === "discount"
                                          ? ""
                                          : "discount"
                                      )
                                    }
                                  >
                                    Discount
                                  </h3>
                                  <h3 className="box-heading">
                                    ₹ {discountRate}
                                  </h3>
                                </div>
                                <ul
                                  className="box-dropdown w3-animate-left"
                                  style={{
                                    display:
                                      toggleFare === "discount"
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  <li className="box-content">
                                    <div className="box-subcontent">
                                      <p className="box-list">Discount</p>
                                      <p className="box-list">
                                        ₹ {discountRate.toLocaleString("en-IN")}
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            )}

                            <hr className="mb-0" />
                            <div className="main-box">
                              <div
                                className="box-header accordion"
                                id="acc_image_five"
                              >
                                <h3 className="box-heading">Total Amount:</h3>
                                <h3 className="box-heading">
                                  ₹ {totalFareAmmount.toLocaleString("en-IN")}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <h3 className="h3-bold">Fare Rules</h3>
                        {storeFareDetails[0]?.Segments[0]?.Rules?.map(
                          (fareRules: any, index: number) => (
                            <table
                              className="table mb-2 table-hover table-bordered"
                              key={`dare-details-index=${index}`}
                            >
                              <thead style={{ backgroundColor: "#fff" }}>
                                <tr>
                                  <th scope="col">
                                    {fareRules.OrginDestination}
                                  </th>
                                  <th scope="col">ADULT</th>
                                </tr>
                              </thead>
                              {fareRules?.Rule?.map(
                                (rule: any, index: number) => (
                                  <tbody key={`fare-rule-table-${index}`}>
                                    <tr>
                                      <td className="active" colSpan={2}>
                                        {rule.Head !==
                                          "ATO Service Fee(Per Pax/ Per Journey)" &&
                                          rule.Head.replace(/\([^)]*\)/g, "")}
                                      </td>
                                    </tr>
                                    {rule.Head !==
                                      "ATO Service Fee(Per Pax/ Per Journey)" &&
                                      rule?.Info?.map(
                                        (infoRule: any, index: number) => (
                                          <tr key={`fare-rule-row-${index}`}>
                                            <td data-label="">
                                              {infoRule.Description}
                                            </td>
                                            <td data-label="">
                                              {formatAmountAndCurrency(
                                                infoRule?.AdultAmount,
                                                infoRule?.CurrencyCode
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </tbody>
                                )
                              )}
                            </table>
                          )
                        )}

                        {/* add fareruletext */}
                        {storeFareDetails[0]?.Segments[0]?.Rules?.map(
                          (infoRule: any, index: number) => (
                            <>
                              <div className="" key={`fare-rule-row-${index}`}>
                                <p className="fare-rule-text px-3 py-2">
                                  {infoRule.FareRuleText}
                                </p>
                              </div>
                            </>
                          )
                        )}

                        <ul className="list-group">
                          <li className="list-itme">
                            The above data is indicative; fare rules are subject
                            to changes by the Airline from time to time
                            depending upon Fare class, and change/cancellation
                            fee amount may also vary based on fluctuations in
                            currency conversion rates.
                          </li>
                          <li className="list-itme">
                            Although we will try to keep this section updated
                            regularly.
                          </li>
                          <li className="list-itme">
                            Feel free to call our Contact ‘Centre for the exact
                            cancellation/change fee.
                          </li>
                          <li className="list-itme">
                            Cancellation/Date change requests will be accepted
                            30 hours prior to departure.
                          </li>
                          <li className="list-itme">
                            GST and RAF charges applicable on the cancellation
                            penalty.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
                {toggleTabs === "Free Baggage" && (
                  <div className="mt-3 baggge">
                    <div className="bagger-section">
                      <table className="table mb-2 table-hover table-bordered">
                        {baggageDetails !== undefined &&
                          baggageDetails?.Journey &&
                          combinedBaggage?.map((bag: any, index: number) => (
                            <>
                              {bag.SSR.map((ssrBag: any) => (
                                <>
                                  <thead
                                    style={{ backgroundColor: "#fff" }}
                                    key={`ssr-table-${index + Math.random()}`}
                                  >
                                    <tr>
                                      <th className="active">Sector/Flight</th>
                                      <th className="active">
                                        Check in Baggage
                                      </th>
                                      <th className="active">Cabin Baggage</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td data-label="Sector/Flight	">
                                        {bag?.DepartureCode} -{" "}
                                        {bag?.ArrivalCode}
                                      </td>

                                      <>
                                        {bag.SSR && (
                                          <>
                                            <td data-label="Check in Baggage">
                                              {
                                                ssrBag?.Description?.replace(
                                                  /\s/g,
                                                  ""
                                                ).split(",")[0]
                                              }{" "}
                                              (
                                              {ssrBag?.PTC === "ADT"
                                                ? "Adult"
                                                : ssrBag?.PTC === "CHD"
                                                ? "Child"
                                                : "Infant"}{" "}
                                              )
                                            </td>
                                            <td data-label="Cabin Baggage">
                                              {ssrBag?.Description && (
                                                <>
                                                  {
                                                    ssrBag?.Description?.replace(
                                                      /\s/g,
                                                      ""
                                                    ).split(",")[1]
                                                  }{" "}
                                                  (
                                                  {ssrBag?.PTC === "ADT"
                                                    ? "Adult"
                                                    : ssrBag?.PTC === "CHD"
                                                    ? "Child"
                                                    : "Infant"}{" "}
                                                  )
                                                </>
                                              )}
                                            </td>
                                          </>
                                        )}
                                      </>
                                    </tr>
                                  </tbody>
                                </>
                              ))}
                            </>
                          ))}
                      </table>
                      <div className="short-bagger">
                        <ul className="list-group">
                          <li className="list-itme">
                            The information presented above is as obtained from
                            the airline reservation system. Maashree.com does
                            not guarantee the accuracy of this information.
                          </li>
                          <li className="list-itme">
                            The baggage allowance may vary according to
                            stop-overs, connecting flights, and changes in
                            airline rules.
                          </li>
                        </ul>
                      </div>
                      {/* <div className="desc-section">
                        <p className="desc-text mb-0">
                          The information presented above is as obtained from
                          the airline reservation system. Maashree does not
                          guarantee the accuracy of this information. The
                          baggage allowance may vary according to stop-overs,
                          connecting flights and changes in airline rules.
                        </p>
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      ))}
    </>
  );
};
